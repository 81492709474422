<template>
  <div class="staff-list-item">
    <div class="image-container">
      <img
        :src="bioData.portfolioImage[0].url"
        :alt="bioData.portfolioImage[0].title"
      />
    </div>

    <div class="content-container">
      <h4>{{ bioData.titleText }}</h4>
      <div v-html="bioData.bio"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StaffListItem',
  props: {
    staffListItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bioData() {
      return this.staffListItem.bioContainer[0]
    },
  },
  mounted() {
    this.preLoadImage()
  },
  methods: {
    preLoadImage() {
      const image = document.createElement('img')

      image.onload = () => {
        this.$emit('loaded')
      }

      image.src = this.bioData.portfolioImage[0].url
    },
  },
}
</script>

<style lang="scss" scoped>
.staff-list-item {
  @apply flex flex-wrap w-full mb-16;
  padding: 20px 0;
  border-bottom: 1px solid #4f4f4f;

  .image-container {
    @apply w-full flex justify-center items-start;
    img {
      @apply w-full;
      padding-right: 0;
      max-width: 360px;
    }
  }

  .content-container {
    @apply w-full;

    h4 {
      @apply text-white text-left;
      font-family: OswaldLight, serif;
      font-size: 24px;
      border-bottom: 1px solid #4f4f4f;
      line-height: 2em;
    }

    div {
      @apply text-left;
      padding-top: 20px;
      color: #cfcfcf;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.7em;
      white-space: pre-wrap;
    }
  }

  @media screen and (min-width: 900px) {
    .image-container {
      @apply w-1/3;

      img {
        padding-right: 20px;
      }
    }
    .content-container {
      @apply w-2/3;

      h4 {
        font-size: 34px;
      }
    }
  }
}
</style>
