<template>
  <div id="aboutUsView">
    <SimpleContentBlock
      v-if="simpleContentData"
      :simple-content-data="simpleContentData"
    />
    <StaffList
      v-if="selectedStaff.length"
      :staff-list="selectedStaff"
      @loaded="staffListLoaded"
    />
  </div>
</template>

<script>
import helpers from '@/api/helpers/helpers'
import api from '@/api'
import SimpleContentBlock from '@/components/UI/SimpleContentBlock'
import StaffList from '@/components/Staff/StaffList'
import { mapMutations } from 'vuex'

export default {
  name: 'AboutUs',
  components: { StaffList, SimpleContentBlock },
  data() {
    return {
      simpleContentData: null,
      staffListData: [],
    }
  },
  computed: {
    selectedStaff() {
      return this.staffListData.selectedStaff || []
    },
  },
  mounted() {
    this.loadAboutUs()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadAboutUs() {
      this.setAboutUsData(helpers.readStoredResponse('view.about-us'))

      api.views.loadAboutUs().then((response) => {
        this.setAboutUsData(response)
      })
    },
    setAboutUsData(response) {
      if (!response || !response.entry) return

      const entry = response.entry

      this.simpleContentData = entry.simpleContentBlock[0] || null
      this.staffListData = entry.staffList[0] || []
    },
    staffListLoaded() {
      setTimeout(() => {
        this.setPageLoadingFalse()
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
#aboutUsView {
  @apply bg-black;
  margin-bottom: 200px;
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>
