<template>
  <div class="staff-list-container">
    <div class="container">
      <StaffListItem
        v-for="staffItem in staffList"
        :key="staffItem.id"
        :staff-list-item="staffItem"
        @loaded="setItemLoaded"
      />
    </div>
  </div>
</template>

<script>
import StaffListItem from '@/components/Staff/StaffListItem'
export default {
  name: 'StaffList',
  components: { StaffListItem },
  props: {
    staffList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      itemsLoaded: [],
    }
  },
  methods: {
    setItemLoaded() {
      this.itemsLoaded.push(true)

      if (
        this.staffList.length !== 0 &&
        this.itemsLoaded.length === this.staffList.length
      ) {
        this.$emit('loaded')
      }
    },
  },
}
</script>

<style scoped></style>
